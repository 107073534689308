import React, { createElement } from "react";
import Components from "@/components/components";
import ScrollCarousel from "./scroll-carousel";
import { ContentCarouselStoryblok } from "@/types/component-types-sb";

interface Props {
  blok: ContentCarouselStoryblok;
  className?: string;
}

const Carousel = (props: Props) => {
  const {
    blok: { items, component },
    className,
  } = props;

  return (
    <ScrollCarousel type={component} className={className}>
      {items &&
        items.length > 0 &&
        items.map((blok) =>
          createElement(Components(blok.component), {
            blok: { ...blok },
            key: blok._uid,
            className: `carousel-${blok.component}`,
          })
        )}
    </ScrollCarousel>
  );
};

export default Carousel;
